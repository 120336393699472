<script setup lang="ts">
defineProps({
    title: {
        type: String,
        default: ""
    },
    description: {
        type: String,
        default: ""
    },
    keywords: {
        type: String,
        default: ""
    },
    image: {
        type: String,
        default: ""
    }
});

const { fullPath } = useRoute();
const url = computed(() => {
    const newPath = fullPath.endsWith("/") ? fullPath.substring(0, fullPath.length - 1) : fullPath;
    return `https://${window?.location?.host}${newPath}`;
});
</script>

<template>
        <Html lang="en">
            <Head>
                <Title>{{ title }}</Title>
                <Meta name="description" :content="description" />
                <Meta name="keywords" :content="keywords" />
                <Meta name="robots" content="index,follow" />
                <Meta name="author" content="Allmedica" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Language" content="en" />
                <Meta property="og:title" :content="title" />
                <Meta property="og:description" :content="description" />
                <Meta property="og:locale" content="en" />
                <Meta property="og:type" content="website" />
                <Meta property="og:url" :content="url" />
                <Link rel="canonical" :href="url" />
                <Meta v-if="image" property="og:image" :content="`https://allmedica.com${image}`" />
            </Head>
            <slot />
        </Html>
</template>
